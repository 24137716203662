<template>
  <div>
    <HomePageTop v-if="cart"></HomePageTop>
    <Dialog ref="dialog" :config="config"></Dialog>
    <div :class="cart ? 'main' : ' main hei'" style="margin-top: 20px">
      <div class="type" v-if="cart">
        <button :class="{ isbtn: type == 0 }">
          <!-- 购物车 -->{{ $fanyi("购物车") }}
        </button>
        <div>（{{ $store.state.cartCount }}）</div>
        <div class="flexAndCenter cursorPointer" style="margin-left: 20px"
             @click="depreciateChecked=!depreciateChecked;depreciateCheckedChange">
          <input v-model="depreciateChecked" type="checkbox" @change.stop="depreciateCheckedChange"/>
          <div>{{ $fanyi("降价") }}</div>
        </div>
      </div>
      <div class="head" :style="$route.name == 'cart' ? 'top: 57px;' : ''">
        <ul>
          <li class="chexk-box" style="font-size: 14px">
            <input type="checkbox" v-model="checked" @change="allChoice"/>
            <span>{{ $fanyi("全选") }}</span>
          </li>
          <li class="goods" style="text-align: left"><!-- 产品 -->{{ $fanyi("产品") }}</li>
          <li class="detail" style="text-align: left"><!-- 详情 -->{{ $fanyi("详情") }}</li>
          <li style="text-align: left"><!-- 单价 -->{{ $fanyi("单价") }}(元)</li>
          <li><!-- 数量 -->{{ $fanyi("数量") }}</li>
          <li><!-- 总价 -->{{ $fanyi("总价") }}(元)</li>
          <li><!-- 操作 -->{{ $fanyi("操作") }}</li>
        </ul>
      </div>
      <!-- 购物车有商品 -->
      <div class="lists" v-if="lists != false">
        <div class="item" v-for="(item, index) in lists" :key="index">
          <h4 class="cursorPointer">
            <input v-model="item.checked" type="checkbox" @change.stop="shopAll(index)"/>
            <span :class="item.shop_type == 1688?'cursorPointer':''"
                  @click="shopGoodsPageJump(item)">{{ $fanyi("店铺名") }} ：{{
                item.shop_name
              }}</span>
            <div v-if="item.shop_info!=undefined&&item.shop_info.sellerIdentities!=undefined" class="flexAndCenter"
                 style="margin-left: 10px">
              <div v-for="(newItem,newIndex) in item.shop_info.sellerIdentities" :key="newIndex">
                <div v-if="newItem=='super_factory'" :style="newIndex==0?'margin-right: 6px':''"
                     class="powerfulMerchantsContainer">
                  <img alt="" src="../../assets/1688/powerfulMerchantsIcon.png">
                  <span>スーパーメーカー</span>
                </div>
                <div v-if="newItem=='powerful_merchants'" :style="newIndex==0?'margin-right: 6px':''"
                     class="superFactoryContainer">
                  <img alt="" src="../../assets/1688/superFactoryIcon.png">
                  <span>実力商家</span>
                </div>
              </div>
            </div>
          </h4>
          <!-- 单个商品 -->
          <div class="list">
            <ul v-for="(listItem, listIndex) in item.goods" :key="listIndex">
              <li class="checkBox" style="padding-top: 24px;">
                <div class="check">
                  <input type="checkbox" v-model="listItem.checked" @change="oddChoice(index)"/>
                </div>
                <div class="img">
                  <a href="javascript:;"
                     @click="$fun.toCommodityDetails(listItem.goods_id,listItem.from_platform)">
                    <el-popover placement="right" trigger="hover">
                      <img :src="listItem.pic" style="width: 300px; height: 300px"/>
                      <img :src="listItem.pic" slot="reference"/>
                    </el-popover>
                  </a>
                </div>
              </li>
              <li class="goods" style="padding-top: 24px;">
                <div class="text">
                  <el-popover placement="bottom" width="400" trigger="hover">
                    <p>
                      <a href="javascript:;"
                         @click="$fun.toCommodityDetails(listItem.goods_id,listItem.from_platform)">
                        {{ listItem.goods_title }}
                      </a>
                    </p>
                    <p :title="listItem.goods_title" slot="reference">
                      <a href="javascript:;"
                         @click="$fun.toCommodityDetails(listItem.goods_id,listItem.from_platform)"
                         style="font-weight: 400">
                        <span v-if="listItem.from_platform==1688" class="goodsType">1688</span>{{
                          listItem.goods_title
                        }}
                      </a>
                    </p>
                  </el-popover>
                  <input class="bei" type="text" v-model="listItem.client_remark" @blur="editCartRemark(listItem)"/>
                </div>
              </li>
              <!-- 商品详情 -->
              <li class="detail" style="position: relative"
                  :style="listItem.showGoodsDetails===true?'border: 1px dashed #B4272B':''"
                  @mouseenter="mouseenter(index,listIndex)"
                  @mouseleave="mouseleave(index,listIndex)" @mousedown="mousedown(index,listIndex)">
                <div v-if="(listItem.leftIcon===true||listItem.leftIcon===false)&&listItem.showGoodsDetails===false">
                  <el-popover placement="bottom" trigger="hover">
                    <p v-for="(detailItem, detailIndex) in listItem.detail"
                       :key="detailIndex" style="font-weight: 400;color: #999">
                      {{ detailItem.key }}:{{ detailItem.value }}
                    </p>
                    <div slot="reference" class="DetailAll" style="cursor: pointer">
                      <div v-if="listItem.leftIcon===true" class="leftIcon fontSize14">{{ $fanyi("编辑") }}</div>
                      <div v-if="listItem.showGoodsDetails===true" class="checkLeftIcon fontSize14">
                        {{ $fanyi("编辑") }}
                      </div>
                      <p v-for="(detailItem, detailIndex) in listItem.detail"
                         :key="detailIndex" style="font-weight: 400;color: #999">
                        {{ detailItem.key }}:{{ detailItem.value }}
                      </p>
                    </div>
                  </el-popover>
                </div>
                <div v-if="listItem.showGoodsDetails===true">
                  <el-popover placement="bottom" trigger="manual" v-model="listItem.showGoodsDetails">
                    <div v-if="listItem.goodsInfo!=undefined" class="goodsDetailAll">
                      <div class="goodsDetailAllOneBox"
                           v-for="(detailItem, detailIndex) in listItem.goodsInfo.specification"
                           :key="detailIndex">
                        <h1 class="detailTitle">{{ detailItem.keyC }}:</h1>
                        <div class="valueCBoxList">
                          <div v-for="(valueCItem,valueCIndex) in detailItem.valueC"
                               :key="valueCIndex" :class="detailItem.active===valueCIndex?'active':''"
                               @click="updateGoodsDetailValue(detailItem,valueCIndex)">{{ valueCItem.name }}
                          </div>
                        </div>
                      </div>
                      <div class="updateDetailOption">
                        <div
                            @click="updateGoodsDetail(listItem);listItem.showGoodsDetails=false;listItem.checkLeftIcon=false;listItem.leftIcon=false;">
                          {{ $fanyi("确定") }}
                        </div>
                        <div
                            @click="cancelUpdateGoodsDetailValue(listItem)">
                          {{ $fanyi("取消") }}
                        </div>
                      </div>
                    </div>
                    <div slot="reference" class="DetailAll" style="cursor: pointer">
                      <div v-if="listItem.leftIcon===true" class="leftIcon fontSize14">{{ $fanyi("编辑") }}</div>
                      <div v-if="listItem.showGoodsDetails===true" class="checkLeftIcon fontSize14">
                        {{ $fanyi("编辑") }}
                      </div>
                      <p v-for="(detailItem, detailIndex) in listItem.detail"
                         :key="detailIndex" style="font-weight: 400;color: #999">
                        {{ detailItem.key }}:{{ detailItem.value }}
                      </p>
                    </div>
                  </el-popover>
                </div>
              </li>
              <li class="price" style="padding-top: 24px;">
                <div class="price-box">
                  <!-- 单价 -->
                  <div class="danjia">
                    <b>
                      ￥{{ Number(listItem.price) }}
                    </b>
                    <el-popover v-if="Number(listItem.price)<Number(listItem.price_init)" placement="bottom"
                                trigger="hover"
                                @show="popoverShowNumberStatistics"
                                width="302">
                      <div class="priceComparisonContainer">
                        <div class="priceComparisonHeaderContainer">
                          <div class="oldPrice">
                            <div>{{ $fanyi("加入时价") }}</div>
                            <div>¥{{ listItem.price_init }}</div>
                          </div>
                          <div class="newPrice">
                            <div>{{ $fanyi("当前价") }}</div>
                            <div>¥{{ listItem.price }}</div>
                          </div>
                        </div>
                        <div class="priceComparisonFooterContainer">
                          <div class="borderDashedDFDFDF"></div>
                          <div class="borderDashedDFDFDF"></div>
                          <div class="borderDashedDFDFDF">
                            <div>{{
                                $fanyi("距加入降")
                              }}¥{{ (Number(listItem.price_init) - Number(listItem.price)).toFixed(2) }}
                            </div>
                          </div>
                          <div class="priceComparisonTable">
                            <div class="priceComparisonTableOldContainer">
                              <div>¥{{ listItem.price_init }}</div>
                              <div></div>
                            </div>
                            <div class="priceComparisonTableNewContainer">
                              <div>¥{{ listItem.price }}</div>
                              <div></div>
                            </div>
                          </div>
                          <div class="priceComparisonTableLabelContainer">
                            <div>{{ $fanyi("加入时价") }}</div>
                            <div>{{ $fanyi("当前价") }}</div>
                          </div>
                        </div>
                      </div>
                      <div slot="reference" class="depreciateReferenceContainer">
                        <div>{{ $fanyi("降价") }}</div>
                        <div>¥{{ (Number(listItem.price_init) - Number(listItem.price)).toFixed(2) }}</div>
                      </div>
                    </el-popover>
                  </div>
                </div>
              </li>
              <!-- 选择数量 -->
              <li style="text-align: center;padding-top: 24px" class="setNum">
                <el-input-number
                    v-model="listItem.num"
                    :step="1"
                    step-strictly
                    size="mini"
                    :min="1"
                    @change="numAmend(index, listIndex, listItem)"></el-input-number>
                <div v-if="listItem.price_ranges!=null&&listItem.price_ranges!=''" class="startingLot">
                  {{ $fanyi("起批量") }}≥{{ listItem.price_ranges[0].startQuantity }}
                </div>
              </li>
              <!-- 总价 -->
              <li style="text-align: center;padding-top: 24px">
                <span>{{ ($fun.roundFixed((listItem.price) * listItem.num)) }}元</span>
              </li>
              <li style="text-align: center;padding-top: 24px">
                <b class="del cursorPointer colorB4272B" style="font-weight: 400"
                   @click="delCart(listItem.id, index, listIndex)">
                  {{
                    $fanyi("删除")
                  }}</b>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 购物车无商品 -->
      <div class="cart" v-else>
        <div>
          <img src="../../assets/img/gwcc.png" alt=""/>
          <div class="nav">
            <p>{{ $fanyi("购物车为空") }}</p>
            <p>
              <a href="javascript:;" @click="$fun.routerToPage('/')">{{ $fanyi("回到顶部") }} |</a>
              <a href="javascript:;" @click="$fun.routerToPage('/user/commodity')">{{ $fanyi("你的最爱") }} |</a>
              <a to="#" @click="$fun.routerToPage('/collection')">{{ $fanyi("资料库") }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <explosivePalletsAreRecommended/>
    <div class="technicalServiceSupport">
      <div>1688 より、一部技術、情報サポートを受けて提供させて頂いております</div>
    </div>
    <div :class="{ order: this.$route.name != 'cart' }" class="foot">
      <input v-model="checked" type="checkbox" @change="allChoice"/>
      <b class="quanXuan">{{ $fanyi("全选") }}</b>
      <b class="del" style="color:#B4272B" @click="delCart(false)"> {{ $fanyi("删除") }}</b>
      <div class="total">
        <div class="goodsNum">
          <p>
            <span class="goodsNumLeft">{{ $fanyi("已选商品") }}： </span>
            <span class="goodsNumCenter">{{ isTotal }}</span><span class="goodsNumRight">{{ $fanyi("件") }}</span>
          </p>
        </div>
      </div>
      <div class="total">
        <p>
          <!-- 总价: -->{{ $fanyi("合计：") }}
          <span>
              <span class="price">{{ $fun.roundFixed(totalPrice) }} 元</span>
            </span>
        </p>
      </div>
      <button v-if="this.$route.name == 'cart'" :class="{ btn: idArr.length > 0 }"
              :disabled="idArr.length == 0" @click="jieSuan">
        {{ $fanyi("去订货") }}
      </button>
    </div>
  </div>
</template>
<script>
import HomePageTop from "../../components/head/HomePageTop";
import Dialog from "../../components/public/Dialog";
import FootVue from "../../components/foot/Foot.vue";
import debounce from "@/utlis/debounce";
import explosivePalletsAreRecommended from '../../components/explosivePalletsAreRecommended/index.vue'

export default {
  data() {
    return {
      token: localStorage.getItem("token"),
      checked: false, //全选
      depreciateChecked: false,//降价筛选
      type: 0, // 类型
      num: 0, //
      isTotal: 0, // 选中的商品数
      shopTotal: 0, // 选中的店铺
      totalPrice: 0, // 选中的商品总价
      lists: null, // 数据
      idArr: [], // 选中的ID
      cart: true,
      // 弹窗的属性
      config: {
        width: "480px",
        title: this.$fanyi("提醒"),
        btnTxt: [this.$fanyi("确定"), this.$fanyi("取消")],
      },
    };
  },
  components: {
    HomePageTop,
    Dialog,
    FootVue,
    explosivePalletsAreRecommended
  },
  created() {
    this.getCartList();
    if (this.$route.name == "OrderDetails") {
      this.cart = false;
    }
  },
  methods: {
    // 获取数据
    getCartList() {
      this.$api.goodsCartList({
        priceCut: this.depreciateChecked == true ? 1 : 0,
      }).then((res) => {
        if (res.code != 0) {
          this.lists = [];
          return false;
        }
        res.data.forEach((item) => {
          item.goods.forEach((goodsItem) => {
            if (typeof goodsItem.detail === 'string') {
              goodsItem.detail = JSON.parse(goodsItem.detail);
            }
            if (goodsItem.price_ranges !== '' && typeof goodsItem.detail === 'string') {
              goodsItem.price_ranges = JSON.parse(goodsItem.price_ranges);
            }
            goodsItem.leftIcon = false;
            goodsItem.checkLeftIcon = false;
            goodsItem.showGoodsDetails = false;
          })
        })
        this.lists = res.data;
      });
    },
    //更新商品的规格
    updateGoodsDetail(item) {
      let newDetailList = [];
      let str = '';
      let sku_id = '';
      let spec_id = '';
      let pic = '';
      if (item.goodsInfo != undefined) {
        if (item.goodsInfo.specification.length > 0) {
          item.goodsInfo.specification.forEach((specificationItem, index) => {
            newDetailList.push({
              key: specificationItem.keyC,
              value: specificationItem.valueC[specificationItem.active].name
            })
            if (specificationItem.valueC[specificationItem.active].picUrl != '') {
              pic = specificationItem.valueC[specificationItem.active].picUrl
            }
            str += specificationItem.valueC[specificationItem.active].name + '㊖㊎'
          })
          str = str.substr(0, str.length - 2);
        }
        if (item.goodsInfo.goodsInventory.length > 0) {
          item.goodsInfo.goodsInventory.forEach((goodsInventoryItems) => {
            if (str === goodsInventoryItems.keyC) {
              sku_id = goodsInventoryItems.valueC[0].skuId;
              spec_id = goodsInventoryItems.valueC[0].specId;
              goodsInventoryItems.valueC.forEach((valueCItem) => {
                if (item.num === valueCItem.startQuantity || item.num > valueCItem.startQuantity) {
                  item.price = valueCItem.price;
                }
              })
            }
          });
        } else {
          sku_id = item.sku_id;
          spec_id = item.spec_id;
        }
      } else {
        sku_id = item.sku_id;
        spec_id = item.spec_id;
        this.uploadGoodsDetailsInfo(item);
      }
      if (pic !== '') {
        item.pic = pic
      }
      let datas = {
        num: item.num,
        id: item.id,
        price: item.price,
        pic: item.pic,
        detail: JSON.stringify(newDetailList),
        client_remark: item.client_remark,
        sku_id: sku_id,
        spec_id: spec_id,
      };
      this.$api.goodsCartEdit(datas).then((res) => {
        if (res.code != 0) return;
        item.detail = newDetailList;
      });
    },
    //更新商品选中的规格
    updateGoodsDetailValue(detailItem, valueCIndex) {
      detailItem.active = valueCIndex;
      this.$forceUpdate();
    },
    //取消更新商品选中的规格
    cancelUpdateGoodsDetailValue(listItem) {
      listItem.showGoodsDetails = false;
      listItem.checkLeftIcon = false;
      listItem.leftIcon = false;
      if (listItem.goodsInfo.specification.length > 0) {
        listItem.goodsInfo.specification.forEach((item) => {
          item.active = this.$fun.deepClone(item.oldActive);
        })
      }
    },
    shopGoodsPageJump(item) {
      if (item.shop_type == 1688) {
        let href = `/shopGoods?shopId=${item.shop_id}&shopName=${item.shop_name}&shopType=${item.shop_type}`;
        window.open(href, "_blank");
      }
    },
    // 整理数据
    tidyData() {
      this.lists.forEach((item, index) => {
        item.goods.forEach((item1, index1) => {
          // 商品总数
          item1.checked = false; // 商品选择
          item1.univalence = 0; // 显示单价
          let detailStraight = ((detail) => {
            let str = [];
            detail.forEach((detailitem) => {
              str.push(detailitem.value);
            });
            return str.join("㊖㊎");
          })(item1.detail);
          if (item1.goodsInfo.goodsInventory.length > 0 && item1.goodsInfo.specification.length > 0) {
            for (let i = 0; i < item1.goodsInfo.goodsInventory.length; i++) {
              for (let k = 0; k < item1.goodsInfo.specification.length; k++) {
                item1.detail.forEach((detailItem) => {
                  for (let j = 0; j < item1.goodsInfo.specification[k].valueC.length; j++) {
                    if (detailItem.key === item1.goodsInfo.specification[k].keyC && detailItem.value === item1.goodsInfo.specification[k].valueC[j].name) {
                      item1.goodsInfo.specification[k].active = j;
                      item1.goodsInfo.specification[k].oldActive = this.$fun.deepClone(j);
                      this.$forceUpdate();
                    }
                  }
                })
              }
              if (item1.goodsInfo.goodsInventory[i].keyC === detailStraight) {
                item1.goodsInfo.goodsInventory[i].valueC.forEach((valueCItem) => {
                  if (item1.num === valueCItem.startQuantity || item1.num > valueCItem.startQuantity) {
                    item1.price = valueCItem.price;
                    item1.total = (Number(item1.price) * item1.num).toFixed(2);
                  }
                })
                break;
              }
            }
          } else {
            item1.total = (Number(item1.price) * item1.num).toFixed(2);
          }
          if (index === this.lists.length - 1 && index1 === item.goods.length - 1) {
            this.commodityTotal();
          }
        });
      });
    },
    // 修改数量
    numAmend(i, index, item) {
      debounce(() => {
        if (item.num == undefined) {
          item.num = 1;
          this.$forceUpdate();
        }
        //   数量不能小于最小数
        if (item.num < 1) {
          this.lists[i].list[index].num = item.price_range[0].startQuantity;
          return this.$message.error(this.$fanyi("数量不能小于最小数"));
        }
        let newDetailList = [];
        let str = '';
        let sku_id = '';
        let spec_id = '';
        if (item.goodsInfo != undefined) {
          if (item.goodsInfo.specification.length > 0) {
            item.goodsInfo.specification.forEach((specificationItem, index) => {
              newDetailList.push({
                key: specificationItem.keyC,
                value: specificationItem.valueC[specificationItem.active].name
              })
              str += specificationItem.valueC[specificationItem.active].name + '㊖㊎'
            })
            str = str.substr(0, str.length - 2);
          }
          if (item.goodsInfo.goodsInventory.length > 0) {
            item.goodsInfo.goodsInventory.forEach((goodsInventoryItems) => {
              if (str === goodsInventoryItems.keyC) {
                sku_id = goodsInventoryItems.valueC[0].skuId;
                spec_id = goodsInventoryItems.valueC[0].specId;
              }
            });
          } else {
            sku_id = item.sku_id;
            spec_id = item.spec_id;
          }
        } else {
          sku_id = item.sku_id;
          spec_id = item.spec_id;
        }
        let datas = {
          num: item.num,
          id: item.id,
          price: item.price,
          detail: JSON.stringify(item.detail),
          pic: item.pic,
          sku_id: sku_id,
          spec_id: spec_id,
          client_remark: item.client_remark
        };
        this.$forceUpdate();
        this.$api.goodsCartEdit(datas).then((res) => {
          if (res.code != 0) return;
          if (item.goodsInfo != undefined) {
            let str = '';
            item.detail.forEach((items) => {
              str += items.value + '㊖㊎'
            })
            str = str.substr(0, str.length - 2);
            if (item.goodsInfo.goodsInventory.length > 0) {
              item.goodsInfo.goodsInventory.forEach((goodsInventoryItems) => {
                if (str === goodsInventoryItems.keyC) {
                  goodsInventoryItems.valueC.forEach((valueCItem) => {
                    if (valueCItem.startQuantity === item.num || item.num > valueCItem.startQuantity) {
                      item.price = valueCItem.price;
                      item.total = (Number(item.price) * item.num).toFixed(2)
                    }
                  })
                }
              });
            }
          }
        });
        this.commodityTotal();
      }, 1000)
    },
    // 单选
    oddChoice(i) {
      let flag = this.lists[i].goods.every((item) => item.checked == true);
      if (flag) {
        this.lists[i].checked = true;
      } else {
        this.lists[i].checked = false;
      }
      this.isCheckedAll();
      this.commodityTotal();
      this.$forceUpdate();
    },
    // 店铺全选
    shopAll(i) {
      if (this.lists[i].checked) {
        this.lists[i].goods.forEach((item) => {
          item.checked = true;
        });
      } else {
        this.lists[i].goods.forEach((item) => {
          item.checked = false;
        });
      }
      this.$forceUpdate();
      this.isCheckedAll();
      this.commodityTotal();
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.lists.forEach((item, index) => {
          item.checked = true;
          this.shopAll(index);
        });
      } else {
        this.lists.forEach((item, index) => {
          item.checked = false;
          this.shopAll(index);
        });
      }
      this.commodityTotal();
      this.$forceUpdate();
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.lists.every((item, index) => item.checked == true);
      if (flag === true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    // 选中商品数量总数、总价
    commodityTotal() {
      this.totalPrice = 0;
      this.isTotal = 0;
      this.shopTotal = 0;
      this.idArr = [];
      this.lists.forEach((item, i) => {
        if (item.goods != undefined) {
          if (item.goods.some((items) => items.checked)) {
            this.shopTotal += 1;
          }
          item.goods.forEach((listItem, j) => {
            if (listItem.checked) {
              listItem.total = (Number(listItem.price) * listItem.num).toFixed(2)
              this.totalPrice += listItem.total - 0;
              this.isTotal += listItem.num;
              this.idArr.push(listItem.id);
            }
          });
        }
      });
    },
    // 删除
    delCart(id, shopIndex, goodsIndex) {
      let datas = {
        ids: "",
      };
      let msg = this.$fanyi("该商品将移出购物车");
      if (id) {
        datas.ids = id;
      } else {
        if (this.idArr == false) return this.$message.error(this.$fanyi("未选择操作的商品"));
        datas.ids = this.idArr.join(",");
        msg = this.$fanyi("是否将选中的商品移除购物车");
      }
      this.$refs.dialog.open(
          // 文本,复杂的在组件里添加插槽
          msg,
          () => {
            // 确认按钮事件
            this.$api.goodsCartDelete(datas).then((res) => {
              if (res.code != 0) return;
              this.$message({
                type: "success",
                message: this.$fanyi("移除成功"),
              });
              if (id) {
                this.lists[shopIndex].goods.splice(goodsIndex, 1);
                if (this.lists[shopIndex].goods == 0) {
                  this.lists.splice(shopIndex, 1);
                }
              } else {
                // 将购物车选中的选项删除
                for (let i = 0; i < this.lists.length; i++) {
                  let shopItem = this.lists[i];
                  for (let j = 0; j < this.lists[i].goods.length; j++) {
                    let goodsItem = this.lists[i].goods[j];
                    if (this.idArr.indexOf(goodsItem.id) != -1) {
                      shopItem.goods.splice(j, 1);
                      j--;
                    }
                  }
                  if (shopItem.goods.length == 0) {
                    this.lists.splice(i, 1);
                    i--;
                  }
                }
              }
              if (this.checked) {
                this.checked = false;
              }
              // 获取总价
              this.commodityTotal();
              // 重新计算购物车商品数量
              this.$store.dispatch("goodsToCartNum");
              // }
            });
          },
          () => {
          }
      );
    },
    // 备注商品信息
    editCartRemark(item) {
      if (item.beikao == "") return false;
      let newDetailList = [];
      let str = '';
      let sku_id = '';
      let spec_id = '';
      if (item.goodsInfo != undefined) {
        if (item.goodsInfo.specification.length > 0) {
          item.goodsInfo.specification.forEach((specificationItem) => {
            newDetailList.push({
              key: specificationItem.keyC,
              value: specificationItem.valueC[specificationItem.active].name
            })
            str += specificationItem.valueC[specificationItem.active].name + '㊖㊎'
          })
        }
        str = str.substr(0, str.length - 2);
        if (item.goodsInfo.goodsInventory.length > 0) {
          item.goodsInfo.goodsInventory.forEach((goodsInventoryItems) => {
            if (str === goodsInventoryItems.keyC) {
              sku_id = goodsInventoryItems.valueC[0].skuId;
              spec_id = goodsInventoryItems.valueC[0].specId;
            }
          });
        } else {
          sku_id = item.sku_id;
          spec_id = item.spec_id;
        }
      } else {
        sku_id = item.sku_id;
        spec_id = item.spec_id;
      }
      let datas = {
        id: item.id,
        num: item.num,
        price: item.price,
        detail: JSON.stringify(item.detail),
        client_remark: item.client_remark,
        pic: item.pic,
        sku_id: sku_id,
        spec_id: spec_id,
      };
      this.$api.goodsCartEdit(datas).then((res) => {
      });
    },
    // 结算
    jieSuan() {
      if (this.idArr.length > 100) {
        return this.$message.warning(this.$fanyi("一个订单最多添加100个商品"));
      }
      let id = this.idArr.join(",");
      this.$fun.routerToPage("/OrderDetails?id=" + id + "&type=temporary");
    },
    //鼠标移入
    mouseenter(index, listIndex) {
      this.lists[index].goods[listIndex].leftIcon = true;
      if (this.lists[index].goods[listIndex].goodsInfo == undefined) {
        this.uploadGoodsDetailsInfo(this.lists[index].goods[listIndex]);
      }
    },
    //鼠标移出
    mouseleave(index, listIndex) {
      this.lists[index].goods[listIndex].leftIcon = false;
    },
    //鼠标在元素上 按下
    mousedown(index, listIndex) {
      this.lists[index].goods[listIndex].showGoodsDetails = true;
    },
    uploadGoodsDetailsInfo(item) {
      this.$api.getGoodsAttribute({
        shop_type: item.from_platform,
        goods_id: item.goods_id
      }).then((res) => {
        if (res.data.length > 0) {
          item.goodsInfo = {
            goodsInventory: this.$fun.deepClone(res.data[0].goodsInventory),
            specification: this.$fun.deepClone(res.data[0].specification),
          }
          if (res.data[0].goodsInventory.length > 0 && res.data[0].specification.length > 0) {
            for (let i = 0; i < res.data[0].goodsInventory.length; i++) {
              for (let k = 0; k < res.data[0].specification.length; k++) {
                item.detail.forEach((detailItem) => {
                  for (let j = 0; j < res.data[0].specification[k].valueC.length; j++) {
                    if (res.data[0].specification[k].valueC != undefined && res.data[0].specification[k].valueC.length > 0) {
                      if (detailItem.key === res.data[0].specification[k].keyC && detailItem.value === res.data[0].specification[k].valueC[j].name) {
                        item.goodsInfo.specification[k].active = j;
                        item.goodsInfo.specification[k].oldActive = this.$fun.deepClone(j);
                        this.$forceUpdate();
                      }
                    }
                  }
                })
              }
            }
          }
        } else {
          item.goodsInfo = {
            goodsInventory: [],
            specification: [],
          }
        }
      })
    },
    depreciateCheckedChange() {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('购物车是否勾选降价商品数量统计');
      }
      this.getCartList();
    },
    popoverShowNumberStatistics() {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('购物车是否查看降价商品趋势数量统计');
      }
    }
  },
};
</script>

<style lang='scss' scoped>
@import "../../css/mixin";

.main {
  width: $pageWidth;
  margin: 10px auto 30px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(61, 61, 61, 0.1);
  border-radius: 6px;
  padding: 20px 0 0;
  border: 1px solid #e8e8e8;

  .goodsType {
    width: 46px;
    height: 18px;
    display: inline-block;
    background: #FF4000;
    border-radius: 2px;
    margin-right: 7px;
    text-align: center !important;
    line-height: 18px;
    font-weight: bold;
    color: #fff !important;
  }

  .superFactoryContainer, .powerfulMerchantsContainer {
    width: 88px;
    height: 24px;
    background: #FFF5F5;
    border-radius: 4px;
    padding: 0 6px;
    display: flex;
    align-items: center;

    img {
      width: 18px;
      height: 17px;
      margin-right: 5px;
    }

    span {
      color: #F72A2B;
      font-size: 12px;
      line-height: normal;
      font-weight: 400;
    }
  }

  .powerfulMerchantsContainer {
    background: #F8F6FF !important;
    width: 136px !important;

    span {
      color: #3700E1 !important;
    }
  }

  /deep/ input[type="checkbox"]:checked {
    background: #4A91E9;
  }

  &.hei {
    height: 450px;
    overflow: auto;
  }

  input {
    vertical-align: middle;
  }

  .type {
    display: flex;
    align-items: center;

    button {
      font-size: 20px;
      font-weight: 600;
      color: #B4272B;
      background-color: transparent;
      line-height: 10px;
      letter-spacing: 0px;
      margin: 26px 0 26px 25px;
    }

    div {
      font-size: 20px;
      font-weight: 600;
      color: #B4272B;
    }

    .flexAndCenter input {
      margin-top: 3px;
    }

    .flexAndCenter div {
      margin-left: 5px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
    }
  }

  input {
    width: 16px;
    height: 16px;
  }

  .head {
    // top的设置在行内样式里
    height: 56px;
    margin: 0 20px;
    background: #eee;
    position: sticky;
    z-index: 4;

    ul {
      display: flex;
      line-height: 56px;
      font-size: 15px;
      font-weight: 600;

      .chexk-box {
        text-align: left;
        display: flex;
        padding-left: 10px;
        align-items: center;

        input {
          margin-right: 10px;
        }
      }

      .goods {
        flex: 0 0 240px;
      }


      li {
        flex: 1;
        text-align: center;

        input {
          margin-left: 10px;
        }

        &.detail {
          flex: 0 0 200px;
        }
      }
    }
  }

  .lists {
    margin: 0 20px;
    padding-bottom: 20px;
  }

  .item {
    h4 {
      font-weight: bold;
      font-size: 16px;
      line-height: 64px;
      display: flex;
      align-items: center;
      color: #333333;

      input {
        margin: 0 20px;
      }

      a {
        color: #333;

        &:hover {
          color: #999;
        }
      }

      img {
        margin-left: 5px;
        vertical-align: middle;
        float: none;
      }
    }

    .list {
      background: #F9F9F9;

      .fg {
        width: 1360px;
        height: 10px;
        background: transparent;
        margin: 0 auto;
      }

      ul:hover {
        background: #FAF2F2;
      }

      ul {
        height: 150px;
        display: flex;
        border-top: 1px solid #ededed;

        .checkBox {
          input {
            margin-left: 20px;
          }
        }

        .goods {
          width: 240px;
          min-width: 240px;
          padding-right: 26px;

          .text {
            height: 110px;
            display: flex;
            flex-direction: column;

            > input {
              margin-top: 34px;
              padding-left: 10px;
            }
          }
        }

        .price {
          width: 200px;

          p {
            width: 200px;
            word-break: break-all;
          }
        }

        li {
          // width: 14.28%;
          flex: 1;

          .check {
            height: 80px;
            display: flex;
            align-items: center;
          }

          input {
          }

          &:first-child {
            display: flex;
            margin-right: 10px;

            .img {
              float: left;
              width: 100px;
              height: 100px;
              margin-left: 15px;

              img {
                min-width: 100px;
                min-height: 100px;
                width: 100px;
                height: 100px;
              }
            }
          }

          &.detail {
            position: relative;
            //flex: 0 0 200px;
            width: 100px;
            height: 125px;
            min-width: 100px;
            box-sizing: border-box;
            border: 1px solid transparent;
          }

          &.detail:hover {
            border: 1px dashed #DFDFDF;
          }

          &.detail:active {
            border: 1px dashed #B4272B !important;
          }

          &.selectDetail {
            border: 1px dashed #B4272B;
          }

          &.goods {
            position: relative;
          }

          a {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .bei {
            width: 220px;
            min-height: 26px;
            height: 26px;
            border: 1px solid #DFDFDF;
            border-radius: 3px;
          }

          span {
            font-size: 14px;
            color: #B4272B;
            font-weight: 600;
          }
        }

        .del {
          font-size: 14px;
          color: #333333;

          &:hover {
            color: #B4272B;
          }
        }

        .price {
          .price-box {
            .danjia {
              text-align: left;
            }

            .depreciateReferenceContainer {
              display: flex;
              align-items: center;
              width: 128px;
              padding: 0 10px;
              height: 26px;
              background: #F6E9E9;
              border-radius: 3px;
              cursor: pointer;
              margin-top: 12px;

              div:first-child {
                font-size: 14px;
                color: #333333;
                margin-right: 6px;
              }

              div:last-child {
                font-size: 14px;
                color: #B4272B;
                font-weight: bold;
              }
            }

            p {
              text-align: center;
              padding: 0 10px;
            }

            padding-left: 15px;
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }
  }

  .cart {
    padding: 50px 0;
    text-align: center;
    overflow: hidden;

    > div {
      margin: 0 auto;
      width: 440px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    img {
      margin: 0 auto;
    }

    .nav {
      margin: 50px 0 0 30px;

      p {
        font-size: 20px;
        line-height: 50px;
        text-align: center;

        a {
          color: $homePageSubjectColor;
          font-size: 16px;
        }
      }
    }
  }

  .order {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.foot {
  width: 1400px;
  height: 70px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(10, 11, 34, 0.05);
  border-radius: 10px 10px 0 0;
  position: sticky;
  bottom: -0px;
  line-height: 50px;
  font-weight: 700;
  z-index: 99;
  display: flex;
  align-items: center;
  margin: 0 auto;

  input {
    margin-left: 40px;
    width: 16px;
    height: 16px;
    margin-right: 20px;
  }

  b {
    cursor: pointer;
    font-size: 14px;
    color: #333333;
    line-height: 19px;

    &.quanXuan {
      font-weight: 400;
    }

    &.del {
      font-size: 14px;
      font-weight: 400;
      color: $homePageSubjectColor;
      line-height: 19px;
      margin-left: 40px;
    }
  }

  .total {
    margin: 0 0 0 auto;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding-left: 0;

    p {
      display: flex;
      align-items: center;
      font-size: 14px;
      height: 26px;

      .price {
        color: #B4272B;
        font-weight: bold;
        font-size: 20px;
        max-width: 246px;

        span {
          font-size: 24px;
        }
      }

      .RMBTotal {
        font-weight: 400;
        font-size: 14px;
      }
    }

    span {
      font-size: 14px;
      font-weight: 700;
    }

    .goodsNum {
      margin: 0 0 0 250px;
      position: relative;
      line-height: 30px;
      display: flex;
      font-size: 14px;
      align-items: center;

      .goodsNumLeft, .goodsNumRight {
        font-size: 16px;
        font-weight: 400 !important;
        color: #333;
      }

      .goodsNumCenter {
        margin-right: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #B4272B;
      }
    }
  }

  i {
    margin: 0 20px;
  }

  button {
    width: 200px;
    height: 70px;
    background: #B4272B;
    border-radius: 0 10px 0 0;
    font-size: 30px;
    font-weight: 400;
    color: #fff;
    line-height: 70px;
    /*   */
    cursor: not-allowed;
    text-align: center;
    margin-left: 68px;
  }

  .btn {
    cursor: pointer;
  }
}

.startingLot {
  font-size: 14px;
  margin: 4px 0;
  color: rgb(153, 153, 153);
}

.priceCon {
  display: flex;
  flex-direction: column;
  align-items: center;

  .priceConOpt {
    display: flex;
    align-items: center;

    p {
      font-size: 14px;
      color: #333333;

      span {
        color: #333333 !important;
      }
    }

    &:last-child {
      font-size: 16px;
      color: #333333;
    }
  }
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;
  width: 320px;

  .goodsDetailAllOneBox {
    display: flex;
    margin-bottom: 10px;

    h1 {
      font-size: 16px;
      font-weight: bold;
      padding-top: 3px;
      margin-right: 16px;
      min-width: 40px;
    }

    .valueCBoxList {
      display: flex;
      flex-wrap: wrap;
      border: none;

      div {
        background: transparent;
        border: 1px solid #DFDFDF;
        padding: 5px 10px;
        font-size: 14px;
        margin-right: 16px;
        cursor: pointer;
        margin-bottom: 10px;
      }

      .active {
        color: #B4272B;
        border: 1px solid #B4272B;
      }
    }
  }

  .updateDetailOption {
    display: flex;
    align-items: center;
    margin-left: 65px;

    div {
      padding: 6px 16px;
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }

    div:first-child {
      background: #B4272B;
      color: #fff;
      padding: 7px 16px;
      margin-right: 16px;
    }

    div:last-child {
      border: 1px solid #B4272B;
      color: #B4272B;
    }
  }
}

.DetailAll {
  font-size: 14px;
  color: #000000;
  line-height: 19px;

  max-height: 90%;
  width: 100%;
  padding-top: 26px;
  padding-left: 14px;
  overflow: hidden;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  text-align: center;

  p {
    margin: 0 auto;
    text-align: left;
  }

  .leftIcon, .checkLeftIcon {
    width: 60px;
    height: 26px;
    color: #fff;
    line-height: 26px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
  }

  .checkLeftIcon {
    background-color: #B4272B;
  }

  .leftIcon {
    background-color: #DFDFDF;
  }
}

.technicalServiceSupport {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;

  div {
    width: 600px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    color: #333333;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
  }
}

</style>
